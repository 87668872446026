.title {  
    color: white;  
    padding: 7px 12px;  
    background: rgb(36, 57, 79);  
    box-sizing: border-box; /* Include padding and border in the element's total width */  
    border: none; /* Remove default border */  
    overflow: hidden; /* Prevent overflow */ 
    font-size: small; 
  }  
    
  .content {  
    min-height: 100px;  
    background: rgb(255, 255, 255);  
    font-size: small;  
    font-weight: light;  
  }  
    
  textarea {  
    border: none;  
    width: 100%; /* Ensure the textarea takes full width */  
    min-height: 100px;  
  }  
    
  .sparkle {  
    padding: 15px 0;  
    display: flex;  
    flex-direction: column;  
    gap: 20px;  
    align-items: center;  
  }  

  .lock{
    height: 30px;
    aspect-ratio: 1;
  }